<template>
  <v-card
    id="settings"
    class="py-2 px-4"
    dark
    flat
    style="position: fixed; bottom: 10px; left: 0px; background-color: transparent; z-index: 1050;"
    width="100"
    @click="$vuetify.theme.dark = !$vuetify.theme.dark"
  >
    <v-icon
      large
      dense
      color="secondary"
    >
      {{ $vuetify.theme.dark ? 'mdi-white-balance-sunny' : 'mdi-weather-night' }}
    </v-icon>
  </v-card>
</template>

<script>
  export default {
    name: 'HomeSettings',

    data () {
      return {
        colors: [
          this.$vuetify.theme.currentTheme.primary,
          '#9368e9',
          '#F4511E',
        ],
        menu: false,
      }
    },

    computed: {
      currentThemePrimary: {
        get () {
          return this.$vuetify.theme.currentTheme.primary
        },
        set (val) {
          const target = this.$vuetify.theme.isDark ? 'dark' : 'light'

          this.$vuetify.theme.themes[target].primary = val
        },
      },
    },
  }
</script>
